import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class DepartureinheritancedService {
  @Inject(HttpService) private http!: HttpService;

  public query(param: Dict<any>): Promise<any> {
    return this.http.post('api/clientele/departure/inheritance/query', param);
  }

}