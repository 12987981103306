















































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { isArray } from 'lodash';
import { DepartureinheritancedService } from '@/services/departureinheritanced-service';

@Component
export default class Departureinheritanced extends Vue {
  @Inject(DepartureinheritancedService) private departureinheritancedService!: DepartureinheritancedService;
  public input: string = '';
  public userName: string = '';
  public loading: boolean = false;
  private tableData: Dict<any> = [];

  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };

  private async getList(val?: any, type?: string) {
    let form: any = { pageNum: 1, pageSize: 10, userName: this.userName };
    if (type === 'pagination') {
      // console.log('pagination', val);
      const { pageSize, pageNum } = val;
      form.pageNum = pageNum;
      form.pageSize = pageSize;
    } else{
      form.pageSize = 10;
      form.pageNum = 1;
    }
    try {
      this.loading = true;
      const res = await this.departureinheritancedService.query(form);
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      // console.log('records', records);
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
      }
    } finally {
      this.loading = false;
    }
  }

  private async mounted(): Promise<void> {
    this.getList();
  }

  private clearSearch(): void {
    this.getList();
  }

}
